import React, { useState } from "react";
import LessonLayout from "~/layouts/lesson";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import { StudentCard, TeacherCard } from "~/components/content-card";
import UnorderedList from "~/components/List/Unordered";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <TeacherViewWrapper>
            <StudentCard
              fullScreen={studentFullScreen}
              hidden={teacherFullScreen}
              highSchool={false}
              setFullScreen={setStudentFullScreen}
            >
              <p>The green coffee bean extract website mentioned a "clinical trial." A <strong>clinical trial</strong> is a type of scientific experiment that involves human participants.</p>
              <p>Think about experiments you have done in your science classes. As you carried out these experiments, you have learned some important things about how experiments should be done.</p>
              <p>Work with a partner to think about what is important when designing a good experiment. For example, your list may start with having a testable question. Record your ideas in your science notebook.</p>
              <p>Be ready to share your ideas in a class discussion.</p>
            </StudentCard>
            <TeacherCard
              fullScreen={teacherFullScreen}
              hidden={studentFullScreen}
              highSchool={false}
              setFullScreen={setTeacherFullScreen}
            >
              <p>To help students understand clinical trials in the upcoming steps, students think about what they know about experimental design. After students (working with a partner) have a few minutes to write some of their ideas about what is important when designing an experiment, hold a class discussion. As students share their ideas, record them on the board. Student responses may include</p>

              <UnorderedList>
                <li>asking a question that is testable,</li>
                <li>using one or more comparison groups,</li>
                <li>controlling variables, and</li>
                <li>using adequate numbers of test subjects</li>
              </UnorderedList>

              <p>The list does not need to be complete at this time. Students may recognize other things as they read about clinical trials in the next step. Save this list to refer to later in the lesson.</p>
            </TeacherCard>
          </TeacherViewWrapper>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
